import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

export const state = () => ({
  data: [],
  loading: false,
  error: '',
  activeGame: '',
  competionData: [],
  eventCompetionData: [],
  premiumSportsbookData: [],
  isGetCategoriesForNavigationAPIHit: false
})

export const mutations = {
  set_data(state, data) {
    data.map((d) => {
      switch (d.name.toLowerCase()) {
        case 'cricket':
          d.sort = 0
          break
        case 'soccer':
          d.sort = 1
          break
        case 'tennis':
          d.sort = 2
          break
        case 'Live Casino':
          d.sort = 3
          break
        case 'Live Card':
          d.sort = 4
          break
        case 'horse racing':
          d.sort = 5
          break
        case 'greyhound racing':
          d.sort = 6
          break
        default:
          if (d.allMarketCount > 0) {
            d.sort = 7
            break
          }
          d.sort = 8
          break
      }
      return d
    })
    data.sort((a, b) => a.sort - b.sort)
    state.data = data

    // state.data = remove(state.data, function (singleGame) {
    //   return !singleGame.disabledEvent
    // })

    const newGame = find(state.data, function (singleGame) {
      return singleGame.allMarketCount > 0
    })
    if (!isEmpty(newGame)) {
      state.activeGame = newGame.name
    } else {
      state.activeGame = 'Cricket'
    }
  },
  set_data_premium_sportsbook(state, data) {
    state.premiumSportsbookData = data
  },
  set_competition_data(state, data) {
    state.competionData = data
  },
  set_event_competition_data(state, data) {
    state.eventCompetionData = data
  },

  empty_data(state, data) {
    state.competionData = []
    state.eventCompetionData = []
    // state.data = []
  },

  // set_data_event_access(state, data) {
  //   state.eventData = data
  // },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  reset_data(state) {
    state.data = []
  },
  set_isGetCategoriesForNavigationAPIHit(state) {
    state.isGetCategoriesForNavigationAPIHit = true
  }
}

export const actions = {
  async get({ commit, state }) {
    // If API is already hit, do not call the API again.
    if (state.isGetCategoriesForNavigationAPIHit) return

    commit('set_isGetCategoriesForNavigationAPIHit')

    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        data: reqData,
        url: 'category/getCategoriesForNavigation'
      })

      let categories = data.data
      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          categories = categories.filter(
            (category) => category.name !== 'Horse Racing'
          )
        }
        if (!this.$auth.user.greyhoundRacing) {
          categories = categories.filter(
            (category) => category.name !== 'Greyhound Racing'
          )
        }
        if (!this.$auth.user.cricket) {
          categories = categories.filter(
            (category) => category.name !== 'Cricket'
          )
        }
        if (!this.$auth.user.soccer) {
          categories = categories.filter(
            (category) => category.name !== 'Soccer'
          )
        }
        if (!this.$auth.user.tennis) {
          categories = categories.filter(
            (category) => category.name !== 'Tennis'
          )
        }
        if (!this.$auth.user.sportsbook) {
          categories = categories.filter(
            (category) =>
              category.name !== 'Cricket' &&
              category.name !== 'Soccer' &&
              category.name !== 'Tennis' &&
              category.name !== 'Horse Racing' &&
              category.name !== 'Greyhound Racing'
          )
        }
      }
      commit('set_data', categories)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getPremiumSportsbook({ commit }) {
    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.CONFIG_V2_API_URL,
        data: reqData,
        url: 'betbymenu/getAllMenu'
      })
      commit('set_data_premium_sportsbook', data.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // api call for get competition of left side menu
  async getCompetition({ commit }, payload) {
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          name: payload,
          eventTypeId: '1'
        },
        url: 'category/getCompetitions'
      })

      if (response && response.data && response.data.value) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.category &&
          this.$router.currentRoute.params.category !== 'horse-racing' &&
          this.$router.currentRoute.params.category !== 'greyhound-racing'
        ) {
          commit('set_competition_data', response.data.data)
        }
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // api call for get event of cpmpition left side menu
  async getEventCompetition({ commit }, payload) {
    try {
      // commit('reset_data')
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          _id: payload
        },
        url: 'category/getEventsOfCompitition '
      })

      if (response && response.data && response.data.value) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.category &&
          this.$router.currentRoute.params.category !== 'horse-racing' &&
          this.$router.currentRoute.params.category !== 'greyhound-racing'
        ) {
          commit('set_event_competition_data', response.data.data)
        }
      }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
